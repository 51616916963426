export function asArray<T>(value?: T | T[] | undefined): T[] {
  if (value === undefined) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}

export function splitBy<T>(arr: T[], by: (item: T) => boolean) {
  const targetIndex = arr.findIndex(by);

  if (targetIndex === -1) {
    return {
      previous: arr,
    };
  }

  // Extract the previous values
  const previousValues = arr.slice(0, targetIndex);

  // Extract the target value
  const targetValue = arr[targetIndex];

  // Extract the following values
  const followingValues = arr.slice(targetIndex + 1);

  return {
    previous: previousValues,
    target: targetValue,
    following: followingValues,
  };
}

export function addOrRemoveItem<T>(items: T[], item: T, comparator: (a: T, b: T) => boolean) {
  const index = items.findIndex((i) => comparator(i, item));

  if (index === -1) {
    return [...items, item];
  }

  return items.filter((_, i) => i !== index);
}

export function addItemAtIndex<T>(items: T[], item: T, index: number) {
  if (index === -1) {
    return [...items, item];
  }

  return [...items.slice(0, index), item, ...items.slice(index)];
}

export function partition<T>(input: T[], spacing: number): T[][] {
  var output: T[][] = [];
  for (var i = 0; i < input.length; i += spacing) {
    output[output.length] = input.slice(i, i + spacing);
  }
  return output;
}

export function uniqBy<T>(arr: T[] | undefined, by: (item: T) => string) {
  if (!arr) {
    return [];
  }

  const map = new Map<string, T>();

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    map.set(by(item), item);
  }

  return Array.from(map.values());
}
