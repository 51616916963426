import type { UploadHandler } from "@remix-run/node";
import _ from "lodash";
import { asArray } from "./array";

// Some random value to represent null because FormData can't store null values
export const INPUT_NULL_VALUE = `null-f7ew7ebgwa7`;

export function valueOrNullPlaceholder(value?: any) {
  return !value ? INPUT_NULL_VALUE : value;
}
export function valueOrEmptyString(value?: any) {
  return value === INPUT_NULL_VALUE ? "" : value;
}

// FormData can be a single value or multiple values per key. This detects if the key is
// has multiple values
function detectFormAppends(formData: FormData) {
  let previousKey;
  let appends = new Set();

  for (let [key] of formData) {
    if (key === previousKey) {
      appends.add(key);
    }

    previousKey = key;
  }

  return appends;
}

// The API expects values to be null to mark them for deletion. This detects the placeholder
// null value and replaces it with null.
function setValueOrNull(formObject: any, key: string, value: any) {
  if (Array.isArray(value)) {
    value = value.map((v) => {
      if (v === INPUT_NULL_VALUE) {
        v = null;
      }

      return v;
    });

    value = _.compact(value);
  } else {
    if (value === INPUT_NULL_VALUE) {
      value = null;
    }
  }

  _.set(formObject, key, value);
}

export function toJSON<T>(formData: FormData): T {
  let formObject = {};

  const formAppends = detectFormAppends(formData);

  for (let [key, value] of formData) {
    const currentValue = _.get(formObject, key);
    let actualValue = (formAppends.has(key) ? asArray(currentValue).concat(value) : value) as
      | any[]
      | string
      | null;

    setValueOrNull(formObject, key, actualValue);
  }

  for (const key in formObject) {
    const value = (formObject as any)[key];
    if (Array.isArray(value)) {
      setValueOrNull(formObject, key, value);
    }
  }

  return formObject as T;
}

export function getUploadedFileAsStringHandler(): UploadHandler {
  const uploadHandler: UploadHandler = async ({ data }) => {
    let file: string | null = null;

    const chunks: Uint8Array[] = [];
    for await (const chunk of data) {
      chunks.push(chunk);
    }
    file = globalThis.Buffer.concat(chunks).toString("utf8");
    return file;
  };

  return uploadHandler;
}
